const LogsJson = [
  {
    title: 'Log 1',
    description: 'This is the first log entry. aaaaaaaaaa aaaaa',
    href: 'https://example.com/log1',
    fotos: [
      {
        imageTitle: 'Image 1 for Log 1',
        imagePath: 'https://via.placeholder.com/150'
      },
      {
        imageTitle: 'Image 2 for Log 1',
        imagePath: 'https://via.placeholder.com/150'
      }
    ]
  },
  {
    title: 'Log 2',
    description: 'This is the second log entry.',
    href: 'https://example.com/log2',
    fotos: [
      {
        imageTitle: 'Image 1 for Log 2',
        imagePath: 'https://via.placeholder.com/150'
      }
    ]
  }
];

export default function Logs() {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1">
        <h1 className="text-3xl w-full text-zinc-800 font-extrabold mb-4">
          /logs
        </h1>
        <p className="text-sm mb-4 text-zinc-600 dark:text-zinc-400">
          Here are the latest logs hola hola hola hola hola hola hola hola:
        </p>
      </div>
      <div className=" bg-red-300">
        {LogsJson.map((log, index) => (
          <div key={index} className="w-full">
            <h2 className="text-xl font-bold">{log.title}</h2>
            <p>{log.description}</p>
            <a href={log.href} className="">Read more</a>

            {/* Renderizar las fotos */}
            <div className="mt-4">
              {log.fotos.map((foto, fotoIndex) => (
                <div key={fotoIndex} className="mb-2">
                  <img src={foto.imagePath} alt={foto.imageTitle} className="" />
                  <p>{foto.imageTitle}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}