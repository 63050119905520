import './Home.css';
import PersonalScheduleWidget from '../../widgets/Personal-Schedule/PersonalSchedule';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className="block-container">
      <div className="box-container col-span-2 md:col-span-1 md:row-span-2">
        <h1 className='text-3xl w-full text-zinc-800 font-extrabold mb-4'>Andrei Hrisca</h1>
        <p className='text-sm mb-4 text-zinc-600 dark:text-zinc-400'>
        A Front End developer / DevOps with a passion for building beautiful and functional websites.
        Originally from Romania, now living in Madrid.
        I’m always eager to learn and grow in the IT world.
        In my free time, I stay active by playing basketball, airsoft, and snowboarding.
        </p>
      </div>
      <PersonalScheduleWidget />
      <Link to="/about" className="box-container text-xl w-full text-zinc-800 font-extrabold text-center">/about</Link>
      <Link to="/logs" className="box-container text-xl w-full text-zinc-800 font-extrabold text-center">/logs</Link>
      <Link to="/" className="box-container md:col-span-2 text-xl w-full text-zinc-800 font-extrabold text-center">/components</Link>
      <Link to="/work" className="box-container text-xl w-full text-zinc-800 font-extrabold text-center">/work</Link>
    </div>
  );
}
