import React, { useState, useEffect } from 'react';

const Data = () => {
  const [madridTime, setMadridTime] = useState('');
  const [screenResolution, setScreenResolution] = useState({
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  });
  const [lastVisitCountry, setLastVisitCountry] = useState('');

  useEffect(() => {
    // Getting Madrid local time.
    const getMadridTime = () => {
      const localTime = new Date();
      const dateTimeOptions = {
        timeZone: 'Europe/Madrid',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      setMadridTime(new Intl.DateTimeFormat('en-US', dateTimeOptions).format(localTime));
    };

    // Getting screen resolution.
    const updateScreenResolution = () => {
      setScreenResolution({
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      });
    };

    // Get the last visit's country from localStorage
    const getLastVisit = () => {
      const storedVisit = localStorage.getItem('lastVisitCountry');
      if (storedVisit) {
        setLastVisitCountry(storedVisit);
      }
    };

    // Save the current visit's country using ipinfo.io
    const saveVisit = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json?token='); // Reemplaza con tu token de ipinfo.io
        const data = await response.json();
        const country = data.country;
        setLastVisitCountry(country);
        localStorage.setItem('lastVisitCountry', country);  // Guarda el país en localStorage
      } catch (error) {
        console.error('Error fetching location data:', error);
      }
    };

    // Set interval for the time and listen for screen resize events
    const timeInterval = setInterval(getMadridTime, 1000);
    window.addEventListener('resize', updateScreenResolution);

    // Get and save the last visit's country when the component mounts
    getLastVisit();
    saveVisit();

    // Clean up interval and remove event listener on component unmount
    return () => {
      clearInterval(timeInterval);
      window.removeEventListener('resize', updateScreenResolution);
    };
  }, []);

  return (
    <div>
      <span className="data-local-time">{madridTime}</span>
      <span className="data-screen-resolution">{screenResolution.width}x{screenResolution.height}</span>
      <span className="data-last-visit">Last visit from: {lastVisitCountry}</span>
    </div>
  );
};

export default Data;