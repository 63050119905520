import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import Footer from './components/Footer/footer';

import Home from './pages/Home/Home'
import About from './pages/About/About'
import Logs from './pages/Logs/Logs'
import Work from './pages/Work/Work'
import Data from './widgets/Data/Data'
function App() {
  return (
    <div className="general-container">
      <div>
        <Data />
        <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/work" element={<Work />} />
            </Routes>
          </Router>
        <Footer />
      </div>
    </div>
  );
}

export default App;
